export enum SupplierTypeName {
  物联网传感器 = 1,
  电子设备,
  系统集成
}

export enum CooperateStatusName {
  潜客 = 1,
  意向,
  合作,
  黑名单
}

export enum CommunicateTypeName {
  电话 = 1,
  微信,
  拜访,
  邮件
}

export enum CustomerType {
  政府 = 1,
  事业单位,
  学校,
  开发商,
  物业管理公司,
  企业园区,
  其他
}

export enum MessageType {
  启用 = 0,
  禁用,
  废弃
}
