

import { Vue, Component } from 'vue-property-decorator'
import { Communicate } from '../../types/supplier'
import { CommunicateTypeName } from '@/utils/enum'

@Component
export default class SupplierCommunicateDetail extends Vue {
  communicateId = ''
  communicateTypeName = CommunicateTypeName

  detail: Communicate = {
    communicateTime: '',
    contactUserId: '',
    communicateType: '',
    communicateSubject: '',
    communicateContent: '',
    supplierId: '',
    resourceList: []
  }

  created () {
    this.communicateId = this.$route.query.communicateId as string
    this.loadData()
  }

  loadData () {
    this.$axios.get<Communicate>(this.$apis.supplier.getCommunicationById, {
      communicateId: this.communicateId
    }).then(res => {
      this.detail = res
    })
  }
}
